import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  blogPostsClicked(e) {
    e.preventDefault();

    BootstrapDialog.alert({
      title: I18n.t(
        "javascript.controllers.backend_nav_controller.alerts.blog.title"
      ),
      message: I18n.t(
        "javascript.controllers.backend_nav_controller.alerts.blog.message"
      ),
    });
  }

  eventPostsClicked(e) {
    e.preventDefault();

    BootstrapDialog.alert({
      title: I18n.t(
        "javascript.controllers.backend_nav_controller.alerts.events.title"
      ),
      message: I18n.t(
        "javascript.controllers.backend_nav_controller.alerts.events.message"
      ),
    });
  }
}
