import { Controller } from "stimulus";

// Allows user to upload a file along with their comment. Only one file upload
// per comment is supported by current logic.
//
// <%= simple_form_for(
//   comment,
//   html: { data: {
//     controller: 'comment-upload',
//     action: 'comment-upload#submit' }
//   }) do |f| %>
//   <%= ... %>
//   <%= f.simple_fields_for :attachment do |attachment| %>
//     <%= attachment.hidden_field :file, 'data-target': 'comment-upload.input' %>
//     <div data-target='comment-upload.dashboard' class='uppy-dashboard'>
//   <% end %>
// <% end %>

export default class extends Controller {
  static targets = ["dashboard", "attachmentsFields"];

  uppy = null;

  connect() {
    var that = this;

    this.uppy = Uppy.Core({
      autoProceed: false,
      restrictions: {
        maxFileSize: 10 * 1000000,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 0,
        allowedFileTypes: [
          "image/*",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/x-iwork-pages-sffpages",
          "application/x-iwork-numbers-sffnumbers",
        ],
      },
      locale: {
        strings: I18n.t("javascript.uppy"),
      },
    });

    this.uppy.use(Uppy.Dashboard, {
      target: this.dashboardTarget,
      inline: true,
      width: "100%",
      height: 222,
      hideUploadButton: true,
      locale: {
        strings: I18n.t("javascript.uppy"),
      },
    });

    this.uppy.use(Uppy.AwsS3, {
      getUploadParameters: function (file) {
        return fetch("/presign?filename=" + file.name + "&type=" + file.type, {
          credentials: "same-origin",
        })
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            return {
              method: data.method,
              url: data.url,
              fields: data.fields,
            };
          });
      },
    });

    this.uppy.on("upload-success", function (file, data) {
      const uploadedFileData = JSON.stringify({
        id: file.meta["key"].match(/^cache\/(.+)/)[1],
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      });
      const input = $("<input>").attr({
        type: "hidden",
        name: "comment[attachments_attributes][][file]",
        value: uploadedFileData,
      });
      $(that.attachmentsFieldsTarget).append(input);
    });
  }

  disconnect() {
    this.uppy.close();
  }

  // ACTIONS
  submit(event) {
    event.preventDefault();
    this.uppy.upload().then((result) => {
      event.target.submit();
    });
  }
}
