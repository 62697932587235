import { Controller } from "stimulus"

// User can select a template from a select dropdown and the
// designated text area will be filled with its content.
// Each template option should have a data-body attribute with its
// content.
// <form data-controller='comment-template'
//       data-comment-template-cached-body=''>
//
//   <select data-action='change->comment-template#applyTemplate'>
//     <option data-body="Hi there!"></option>
//   </select>
//
//   <textarea data-target='comment-template.bodyField'
//          data-action='change->comment-template#cacheBody'/>
// </form>


export default class extends Controller {
  static targets = ['bodyField']

  connect() {
    console.log("Connected")
  }

  // ACTIONS
  // cache the body's original content in the event the user selects a template
  // from the options but then decides to not want to use a template but revert
  // to what they originally wrote.
  cacheBody (event) {
    this.data.set('cachedBody', event.target.value)
  }

  applyTemplate(event) {
    var selectInput = event.target;
    var selectedOption = this.getSelectedTemplateOption(selectInput)

    var body = selectedOption.dataset.body

    if (body) {
      this.bodyFieldTarget.value = body
    } else {
      this.bodyFieldTarget.value = this.data.get('cachedBody')
    }
  }

  // PRIVATE

  getSelectedTemplateOption(selectInput){
    console.log("selectedIndex", selectInput.selectedIndex)

    if (selectInput.selectedIndex == -1) {
      console.log("selectedIndex", selectInput.selectedIndex)
      return null;
    }

    return selectInput.options[selectInput.selectedIndex];
  }
}