import { Controller } from "stimulus"

// For sources other than "agency introduction" the
// form is simple and consists of the select dropdown and submit button only.
// If the user selects "agency introduction" the form reveals further fields and
// on submission, an introduction is created in the background to link the
// application with an agency's consultant.
// When the form is contracted to on field:
// - there is confirmation required on submit.
// When the form expands to reveal the extra fields
// - confirmation is required on submit.
// - consultant options are dynamically loaded when a different agency is
//   selected

export default class extends Controller {
  static targets = [
    'sourceSelect',
    'agencyFieldset',
    'partnershipSelect',
    'consultantSelect',
    'submitButton'
  ]

  connect() {
    // page can be loaded to show form errors on previous submission. We need
    // to expand the form if the user has previously attempted to select
    // agency intro
    if (this.isAgencyIntroSelected() ) {
      this.revealAgencyFieldset()
      this.requireConfirmationOnSubmit()
    }
  }

  // ACTIONS

  toggleAgencyFields (event) {
    if ( this.data.get('disabled') == true ) {
      return;
    }

    if ( this.isAgencyIntroSelected() ) {
      this.revealAgencyFieldset()
      this.requireConfirmationOnSubmit()
    } else {
      this.hideAgencyFieldset()
      this.dontRequireConfirmationOnSubmit()
    }
  }

  updateConsultantOptions (event) {
    var partnershipId = this.partnershipSelectTarget.value;

    if (partnershipId === '') {
      this.setConsultantOptionsAsEmpty()
    } else {
      this.fetchAndPopulateConsultantOptions(partnershipId)
    }
  }

  // HELPERS

  setConsultantOptionsAsEmpty() {
    var blankOption = this.consultantSelectTarget.options[0];
    this.consultantSelectTarget.options.length = 0;
    this.consultantSelectTarget.options.add(blankOption);
  }

  fetchAndPopulateConsultantOptions(partnershipId) {
    const that = this;
    var blankOption = this.consultantSelectTarget.options[0];

    $.get("/backend/partnerships/" + partnershipId + "/engaged_consultants", function(data) {
      that.consultantSelectTarget.options.length = 0;
      that.consultantSelectTarget.options.add(blankOption);

      data.forEach(function(item, index) {
        var text = item.full_name_localized
        if ( item.speciality_localized ) {
          text += ` (${item.speciality_localized})`
        }
        var option = new Option(text, item.id);

        that.consultantSelectTarget.options.add(option)
      })
    })
  }

  revealAgencyFieldset() {
    this.agencyFieldsetTarget.classList.remove('hidden')
  }

  hideAgencyFieldset() {
    this.agencyFieldsetTarget.classList.add('hidden')
  }

  requireConfirmationOnSubmit () {
    this.submitButtonTarget.dataset.confirm = this.data.get('confirmationMessage');
  }

  dontRequireConfirmationOnSubmit () {
    this.submitButtonTarget.removeAttribute('data-confirm')
  }

  isAgencyIntroSelected() {
    var agencyIntroValue = 'job_opportunity_source_recruiting_agency'
    return ( this.sourceSelectTarget.value == agencyIntroValue)
  }
}