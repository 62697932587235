import { Controller } from "stimulus"

/*
Hides content on page load and allows the user to click a link or button to toggle revealing/hiding
it. The button has two different labels. One to prompt to reveal the other to hide.

%div{'data-controller' => 'click-to-reveal'}
  %div{'data-target' => 'click-to-reveal.content'}
    %p Some content that will be hidden on page load goes here
  %a{'data-action' => 'click->click-to-reveal#toggle'}
    %span{'data-target' => 'click-to-reveal.revealBtnLabel'}
      = t('main_application.buttons.read_more')
    %span{'data-target' => 'click-to-reveal.hideBtnLabel'}
      = t('main_application.buttons.hide')
*/

export default class extends Controller {
  static targets = [ 'content', 'revealBtnLabel', 'hideBtnLabel']

  connect() {
    this.contentTarget.classList.add('hidden')
    this.hideBtnLabelTarget.classList.add('hidden')
  }

  toggle (event) {
    this.contentTarget.classList.toggle('hidden')
    this.revealBtnLabelTarget.classList.toggle('hidden')
    this.hideBtnLabelTarget.classList.toggle('hidden')
  }
}