import { Controller } from "stimulus";

// The application form asks the user to select how they learn of the job i.e. the source.
// Some of the options make sense to ask the user for more details and other options don't.
// This controller hides/reveals a details textbox depending on the chosen source.

export default class extends Controller {
  static targets = ["sourceSelect", "detailsTextArea"];

  connect() {
    this.detailsTextAreaTarget.classList.add("hidden");
  }

  // ACTIONS
  revealDetailsTextArea(_event) {
    const selectedOption =
      this.sourceSelectTarget.options[this.sourceSelectTarget.selectedIndex];

    if (selectedOption.dataset.requiresDetails == "true") {
      this.detailsTextAreaTarget.classList.remove("hidden");
    } else {
      this.detailsTextAreaTarget.classList.add("hidden");
    }
  }
}
