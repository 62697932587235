import { Controller } from "stimulus"

// Show how many characters a user has remaining to write given a maximum.
// %div{'data-controller' => 'character-count',
//      'data-character-count-maximum => '400' }
//   %input{'data-action' => 'input->character-count#update'}
//   %p
//     %span{'data-target' => 'character-count.count'}
//       0
//     chars

export default class extends Controller {
  static targets = ['count', 'input']

  connect() {
    this._update()
  }

  // ACTIONS

  update (event) {
    this._update()
  }

  // HELPERS

  _update() {
    const maximum = parseInt(this.data.get("maximum"))
    const content = this.inputTarget.value.replace(/\r/, "")
    const numberOfChars = content.length
    const numberRemaining = maximum - numberOfChars
    this.countTarget.innerHTML = numberRemaining
  }

  _stripCarriageReturns(string) {
    return string
  }
}