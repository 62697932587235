import { Controller } from "stimulus"

// Truncate very long content to a configurable height and allow user to click a
// link to expand all.
//
// %div{'data-controller' => 'click-to-expand', 'data-click-to-expand-height'=> '200'}
//   %div{'data-target' => 'click-to-expand.content'}
//     %p some very long content goes here
//     %div{'data-target' => 'click-to-expand.fade'} // this is div to fade the excess count out
//   %a{'data-action' => 'click->click-to-expand#expand', 'data-target' => 'click-to-expand.expand'}
//     = t('main_application.buttons.read_more')

// TODO: improvements.
// 1. dont require HTML markup for the fade div or the expand link.
//    The stimulus controller should insert them.

export default class extends Controller {
  static targets = [ 'content', 'fade', 'expand']

  connect() {
    if (this.contentTarget.clientHeight > parseInt(this.data.get('height'))) {
      const contentStyle = `
        max-height: ${this.data.get("height")}px;
        overflow-y: hidden;
        position: relative;
      `
      const fadeStyle = `
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 30px;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 5%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to top, rgba(255,255,255,1) 5%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      `
      this.contentTarget.setAttribute('style', contentStyle)
      this.fadeTarget.setAttribute('style', fadeStyle)
    } else {
      this.fadeTarget.setAttribute('style', "display: none;")
      this.expandTarget.setAttribute('style', "display: none;")
    }
  }

  expand (event) {
    this.contentTarget.setAttribute('style', 'height: initial; overflow-y: initial;')
    this.fadeTarget.setAttribute('style', 'display: none;')
    event.target.setAttribute('style', 'display: none;')
  }
}