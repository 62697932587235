export const initAvatarField = (target, resultTarget) => {
  const uppy = Uppy.Core({
    autoProceed: false,
    restrictions: {
      maxFileSize: 5 * 1000000,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 0,
      allowedFileTypes: ["image/*"],
    },
    locale: {
      strings: I18n.t("javascript.uppy"),
    },
  });

  uppy.use(Uppy.Dashboard, {
    id: Date.now().toString(),
    target: target,
    inline: true,
    width: "100%",
    height: 200,
    hideUploadButton: true,
    proudlyDisplayPoweredByUppy: false,
    locale: {
      strings: I18n.t("javascript.uppy"),
    },
  });

  uppy.use(Uppy.AwsS3, {
    getUploadParameters: (file) =>
      fetch("/presign?filename=" + file.name + "&type=" + file.type, {
        credentials: "same-origin",
      }).then((response) => response.json()),
  });

  uppy.on("upload-success", (file, data) => {
    const uploadedFileData = JSON.stringify({
      id: file.meta["key"].match(/^cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    });
    resultTarget.value = uploadedFileData;
  });

  return uppy;
};

export const initResumeField = (target, resultTarget) => {
  const uppy = Uppy.Core({
    autoProceed: false,
    restrictions: {
      maxFileSize: 10 * 1000000,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 0,
      allowedFileTypes: [
        "image/*",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/x-iwork-pages-sffpages",
        "application/x-iwork-numbers-sffnumbers",
      ],
    },
    locale: {
      strings: I18n.t("javascript.uppy"),
    },
  });

  uppy.use(Uppy.Dashboard, {
    id: Date.now().toString(),
    target: target,
    inline: true,
    width: "100%",
    height: 200,
    hideUploadButton: true,
    proudlyDisplayPoweredByUppy: false,
    locale: {
      strings: I18n.t("javascript.uppy"),
    },
  });

  uppy.use(Uppy.AwsS3, {
    getUploadParameters: (file) =>
      fetch("/presign?filename=" + file.name + "&type=" + file.type, {
        credentials: "same-origin",
      }).then((response) => response.json()),
  });

  uppy.on("upload-success", (file, data) => {
    const uploadedFileData = JSON.stringify({
      id: file.meta["key"].match(/^cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    });
    resultTarget.value = uploadedFileData;
  });

  return uppy;
};

const textSearch = (str, search) =>
  str.match(RegExp(_.escapeRegExp(search), "i"));

export const containsText = (str, search) => {
  const isBlankSearch = Boolean(search) == false
  if (isBlankSearch) {
    return false
  }

  const matches = textSearch(str, search)

  if (Boolean(matches)) {
    return matches.length > 0
  } else {
    return false
  }
}