import { Controller } from "stimulus";
import { initAvatarField, initResumeField } from "../utils";

export default class extends Controller {
  uppys = []

  connect() {
    document.querySelectorAll('.avatar-field-wrapper').forEach((avatarFieldWrapper) => {
      const avatarField = avatarFieldWrapper.querySelector('.avatar-field')
      const avatarFieldResult = avatarFieldWrapper.querySelector('.avatar-field-result')
      if (avatarField) {
        const uppy = initAvatarField(avatarField, avatarFieldResult)
        this.uppys.push(uppy)
      }
    })

    document.querySelectorAll('.resume-field-wrapper').forEach((resumeFieldWrapper) => {
      const resumeField = resumeFieldWrapper.querySelector('.resume-field')
      const resumeFieldResult = resumeFieldWrapper.querySelector('.resume-field-result')
      if (resumeField) {
        const uppy = initResumeField(resumeField, resumeFieldResult)
        this.uppys.push(uppy)

        const languageCode = resumeFieldWrapper.dataset.languageCode
        const checkbox = document.querySelector('.js-user-resume-checkbox-' + languageCode)

        if (checkbox) {
          const handleChange = () => {
            if (checkbox.checked) {
              uppy.reset()
              resumeFieldWrapper.classList.add('hide')
            } else {
              resumeFieldWrapper.classList.remove('hide')
            }
          }

          handleChange()
          checkbox.addEventListener('change', handleChange)
        }
      }
    })
  }

  submit(e) {
    e.preventDefault()
    const promises = this.uppys.map((_uppy) => _uppy.upload())
    Promise.all(promises).then((result) => {
      e.target.submit()
    })
  }
}
